//
//
//
//
//
//
//
//

import OrderList from "@/components/OrderList";
export default {
  name: "LabOrderHistory",
  components: {
    OrderList
  }
};